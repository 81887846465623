'use client';

import { Group, UnstyledButton, rem, useMantineColorScheme } from '@mantine/core';
import { IconBolt, IconMoon, IconSun } from '@tabler/icons-react';
import { match } from 'ts-pattern';

export function ColorSchemeToggle() {
	const { setColorScheme, colorScheme } = useMantineColorScheme();

	return (
		<Group justify="center" mt="xl">
			{match(colorScheme)
				.with('auto', () => (
					<UnstyledButton onClick={() => setColorScheme('light')}>
						<IconBolt style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
					</UnstyledButton>
				))
				.with('light', () => (
					<UnstyledButton onClick={() => setColorScheme('dark')}>
						<IconSun style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
					</UnstyledButton>
				))
				.with('dark', () => (
					<UnstyledButton onClick={() => setColorScheme('auto')}>
						<IconMoon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
					</UnstyledButton>
				))
				.otherwise(() => null)}
		</Group>
	);
}
