'use client';

import * as React from 'react';

import { ColorSchemeToggle } from '@/components/ColorSchemeToggle/ColorSchemeToggle';
import { Welcome } from '@/components/Welcome/Welcome';

export default function HomePage() {
	return (
		<>
			<Welcome />
			<ColorSchemeToggle />
		</>
	);
}
